import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { Plant } from '@app/_models/Plant';
import { environment } from '../../environments/environment';
import { OrganizationUserRole, User } from '../_models/User';
//import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private activeOrganizationSubject: BehaviorSubject<Organization>;
  public activeOrganization: Observable<Organization>;
  

  constructor(private fb: FormBuilder, private http: HttpClient) { 
    this.activeOrganizationSubject = new BehaviorSubject<Organization>(JSON.parse(localStorage.getItem('currentOrganizaiton')));
    this.activeOrganization = this.activeOrganizationSubject.asObservable();

  }

  public get ActiveOrganization() : Organization {
    if (!this.activeOrganization) {
      console.log('Active org is null?');
      console.log(this.activeOrganizationSubject.value);
      return undefined;
    }
    else {
      console.log('Active org found');
      //console.log(this.activeOrganization);
      console.log(this.activeOrganizationSubject.value);
    }

    return this.activeOrganizationSubject.value;
  }
    
  public set ActiveOrganization(organization: Organization) {
    console.log('Sätter ny aktiv organisation');
    console.log(organization);
    localStorage.setItem('currentOrganizaiton', JSON.stringify(organization));
    this.activeOrganizationSubject.next(organization);

    // todo: Måste detta rensas när man loggar ut?
  }

  list() {
    return this.http.get(`${environment.apiUrl}/organization/`);
  }

  info() {
    return this.http.get(`${environment.apiUrl}/organization/${this.activeOrganizationSubject.getValue().id}` );
  }

  usersActive() {
    return this.http.get(`${environment.apiUrl}/user/ForOrganization/${this.activeOrganizationSubject.getValue().id}`);
  }
  usersNotActive() {
    return this.http.get(`${environment.apiUrl}/user/forOrganizationNotVerified/${this.activeOrganizationSubject.getValue().id}`);
  }

  plants() {
    return this.http.get(`${environment.apiUrl}/plant/ForOrganization/${this.activeOrganizationSubject.getValue().id}`);
  }

  plantInfo(plantId) {
    return this.http.get(`${environment.apiUrl}/plant/${plantId}`);
  }

  updatePlant(plant) {
    return this.http.put(`${environment.apiUrl}/plant/`, plant);
  }

  update(organization) {
    return this.http.put(`${environment.apiUrl}/organization/`, organization);
  }

  addWorkTypeToPlant(plantId, workTypeId) {
    return this.http.post(`${environment.apiUrl}/plant/${plantId}/AddWorkType/${workTypeId}`, { Id: this.activeOrganizationSubject.getValue().id});
  }

  deleteWorkTypeFromPlant(organizationId, plantId, workTypeId) {
    return this.http.delete(`${environment.apiUrl}/plant/${this.activeOrganizationSubject.getValue().id}/${plantId}/DeleteWorkType/${workTypeId}`);
  }

  addOrganizationByUser(organization: Organization, user: User) {
    let orguser: OrganizationUser = {
      id: null, invoiceemail: organization.invoiceEmail, name: organization.name, orgnr: organization.orgNr, streetaddress: organization.streetAddress, zipcode: organization.zipCode,
      firstname: user.firstname, lastname: user.lastname, email: user.email, socialsecuritynr: user.socialSecurityNr, phone:user.phone, city:organization.city
    };
    return this.http.post(`${environment.apiUrl}/organization/UserCreate`, orguser);
  }
}

export class Organization {
  name: string;
  id: number;
  lineOfBusinessId: number;
  orgNr: string;
  streetAddress: string;
  zipCode: string;
  city: string;
  invoiceEmail: string;
  roleCodes: string[];
  roles: string[];
  organizationUserRoles: OrganizationUserRole[];
  inviteCode: string;
  parentOrgId: number;
}

export class OrganizationUser {
  name: string;
  id: number;
  orgnr: string;
  streetaddress: string;
  zipcode: string;
  city: string;
  invoiceemail: string;
  socialsecuritynr: string;
  phone: string;
  firstname: string;
  lastname: string;
  email: string;
}

