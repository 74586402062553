import { InjectionToken } from '@angular/core';
// import { NbMediaBreakpoint } from './services/breakpoints.service';
import { AddSafetyJSThemeOptions } from './services/js-themes/theme.options';

export interface AddSafetyThemeOptions {
  name: string;
}

export const ADDSAFETY_THEME_OPTIONS = new InjectionToken<AddSafetyThemeOptions>('AddSafety Theme Options');
export const ADDSAFETY_MEDIA_BREAKPOINTS = new InjectionToken<AddSafetyThemeOptions[]>('AddSafety Media Breakpoints');
export const ADDSAFETY_BUILT_IN_JS_THEMES = new InjectionToken<AddSafetyThemeOptions[]>('AddSafety Built-in JS Themes');
export const ADDSAFETY_JS_THEMES = new InjectionToken<AddSafetyJSThemeOptions[]>('AddSafety JS Themes');

/**
 * We're providing browser apis with tokens to improve testing capabilities.
 * */
export const ADDSAFETY_WINDOW = new InjectionToken<Window>('Window');
export const ADDSAFETY_DOCUMENT = new InjectionToken<Document>('Document');