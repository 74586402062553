import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models/User';
import { OrganizationService } from './organization.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private organizationService: OrganizationService, private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public updateCurrenctUserValue(user: User) {
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/authentication/login`, { username, password })
      .pipe(map(res => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //console.log(res);
        //console.log(res.user);
        localStorage.setItem('currentUser', JSON.stringify(res.user));
        this.currentUserSubject.next(res.user);

        this.organizationService.list().subscribe(
          (orgRes: any) => {
            //if (this.organizationService.ActiveOrganization) {
            //}
            //else {
            console.log('sätt aktiv org till ' + res.user.mainOrganizationId);

            this.organizationService.ActiveOrganization = orgRes.find(o => o.id == res.user.mainOrganizationId);
            //}
          },
          err => {
            if (err.status == 400)
              console.log('400: ' + err);
            else
              console.log(err);
          }
        );

        return res;
      }));
  }

  loginBI(user:User) {
    //return this.http.post<any>(`${environment.apiUrl}/authentication/login`, { username, password })
    //  .pipe(map(res => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //console.log(res);
        //console.log(res.user);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);

        this.organizationService.list().subscribe(
          (orgRes: any) => {
            //if (this.organizationService.ActiveOrganization) {
            //}
            //else {
            console.log('sätt aktiv org till ' + user.mainOrganizationId);

            this.organizationService.ActiveOrganization = orgRes.find(o => o.id == user.mainOrganizationId);
            //}
          },
          err => {
            if (err.status == 400)
              console.log('400: ' + err);
            else
              console.log(err);
          }
        );
    return user;
        
    /*  }));*/
  }

  InitBankId() {

    return this.http.post<any>(`${environment.apiUrl}/authentication/InitBankId/`,"")
      .pipe(map(res => {
      
        return res;
      }));
  }

  CheckBankId(orderRef : string) {

    return this.http.post<any>(`${environment.apiUrl}/authentication/CheckBankId/${orderRef}`, { orderRef })
      .pipe(map(res => {

        return res;
      }));
  }


  RequestReset(username: string) {

    return this.http.post<any>(`${environment.apiUrl}/user/resetpassword/`, username);
  }

  SetPassword(username: string, pwtoken: string, newpassword: string) {

    return this.http.put<any>(`${environment.apiUrl}/user/setpassword/?pwtoken=${pwtoken}&newpassword=${newpassword}&email=${username}`, newpassword);
  }



  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentOrganizaiton');
    this.currentUserSubject.next(null);
  }
}

export class bankIdShortResponse{
    success: boolean;
    statusMessage: string;
    orderRef: string;
    autoStartToken: string;
    qrImage: string;
}

export class checkBankIdShortResponse {
  success: boolean;
  errorMessage: string;
  statusMessage: string;
  orderRef: string;
  HintCode: string;
  personalNumber: string;
  givenName: string;
  surname: string;
  ipAddress: string;
  userInformation: User;
}



