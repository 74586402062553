import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    ): boolean {

      const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
          console.log(currentUser);
            // logged in so return true
            return true;
        }
    
      this.router.navigate(['/user/login'], { queryParams: { returnUrl: state.url } });
      return false;
    

  }
}
