import { Component } from '@angular/core';
import { Router } from '@angular/router';


import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-root',
  //templateUrl: './app.component.html',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.css'],
  //styleUrls: ['../css/style.scss']
})

export class AppComponent {
  title = 'app';

}
