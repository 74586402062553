import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';

import {
  ADDSAFETY_BUILT_IN_JS_THEMES,
  ADDSAFETY_MEDIA_BREAKPOINTS,
  AddSafetyThemeOptions,
  ADDSAFETY_THEME_OPTIONS,
  ADDSAFETY_JS_THEMES,
  ADDSAFETY_DOCUMENT,
  ADDSAFETY_WINDOW,
} from './theme.options';
import { AddSafetyThemeService } from './services/theme.service';
//import { NbSpinnerService } from './services/spinner.service';
import { AddSafetyJSThemeOptions } from './services/js-themes/theme.options';
import { BUILT_IN_THEMES, AddSafetyJSThemesRegistry } from './services/js-themes-registry.service';
import {
  DEFAULT_MEDIA_BREAKPOINTS,
  AddSafetyMediaBreakpoint,
  AddSafetyMediaBreakpointsService,
} from './services/breakpoints.service';
// import { NbLayoutDirectionService, NbLayoutDirection, NB_LAYOUT_DIRECTION } from './services/direction.service';
// import { NbLayoutScrollService } from './services/scroll.service';
// import { NbLayoutRulerService } from './services/ruler.service';
// import { NbOverlayModule } from './components/cdk/overlay/overlay.module';

export function addsafetyWindowFactory() {
  return window;
}

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
  ],
})
export class AddSafetyThemeModule {

  // TODO: check the options (throw exception?)
  /**
   * Main Theme Module
   *
   * @param addsafetyThemeOptions {AddSafetyThemeOptions} Main theme options
   * @param addsafetyJSThemes {AddSafetyJSThemeOptions[]} List of JS Themes, will be merged with default themes
   * @param nbMediaBreakpoints {AddSafetyMediaBreakpoint} Available media breakpoints
   *
   * @returns {ModuleWithProviders}
   */
  static forRoot(addsafetyThemeOptions: AddSafetyThemeOptions = { name: 'default' },
                 addsafetyJSThemes?: AddSafetyJSThemeOptions[],
                 addsafetyMediaBreakpoints?: AddSafetyMediaBreakpoint[],
                 ): ModuleWithProviders {

    return <ModuleWithProviders> {
      ngModule: AddSafetyThemeModule,
      providers: [
        { provide: ADDSAFETY_THEME_OPTIONS, useValue: addsafetyThemeOptions || {} },
        { provide: ADDSAFETY_BUILT_IN_JS_THEMES, useValue: BUILT_IN_THEMES },
        { provide: ADDSAFETY_JS_THEMES, useValue: addsafetyJSThemes || [] },
        { provide: ADDSAFETY_MEDIA_BREAKPOINTS, useValue: addsafetyMediaBreakpoints || DEFAULT_MEDIA_BREAKPOINTS },
        { provide: ADDSAFETY_WINDOW, useFactory: addsafetyWindowFactory },
        { provide: ADDSAFETY_DOCUMENT, useExisting: DOCUMENT },
        AddSafetyJSThemesRegistry,
        AddSafetyThemeService,
        AddSafetyMediaBreakpointsService,
        // AddSafetySpinnerService,
        // { provide: ADDSAFETY_LAYOUT_DIRECTION, useValue: layoutDirection || NbLayoutDirection.LTR },
        // NbLayoutDirectionService,
        // NbLayoutScrollService,
        // NbLayoutRulerService,
        // ...AddSafetyOverlayModule.forRoot().providers,
      ],
    };
  }
}