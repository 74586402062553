import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, bankIdShortResponse, checkBankIdShortResponse } from './../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { StatisticsService } from '../../services/statistics.service';
import { NgZone } from '@angular/core';
import { Organization, OrganizationService } from '../../services/organization.service';
import { User } from '../../_models/User';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html'
})
export class LoginComponent {
  returnUrl: string;
  isRequest: string;
  isChange: string;
  isNewCompany: string;
  isNewCompanyResult: string;
  isOldLogin: string;
  pwtoken: string;
  username: string;
  socsecurityno: string;
  loading = false;
  submitted = false;
  hidePassword = true;
  loginForm: FormGroup;
  loginFormBankId: FormGroup;
  RequestResetForm: FormGroup;
  SetNewPasswordForm: FormGroup;
  NewCompanyForm: FormGroup;
  error = '';
  bankIdResponse: bankIdShortResponse;
  statusResponse: checkBankIdShortResponse;
  organization: Organization;
  user: User;
  QRimageSource: string = '';
  timeoutIdStatus;
  constructor(private zone: NgZone, private formBuilder: FormBuilder,  private route: ActivatedRoute, private service: AuthenticationService, private organizationservice: OrganizationService, private router: Router, private _snackBar: MatSnackBar) {
    if (this.service.currentUserValue) { 
      console.log(this.service.currentUserValue);
      this.router.navigate(['/']);
    }
    this.organization = { parentOrgId: null, id: null,  name: null, invoiceEmail: null, lineOfBusinessId: null, organizationUserRoles: null, orgNr: null, roleCodes: null, roles: null, streetAddress: null, zipCode: null, inviteCode:null, city:null };
    this.user = { subContractor: null, active: null, county: null, countyId: null, countyIncidentUrl: null, deleted: null, email: null, firstname: null, id: null,verified:null, lastname: null, mainOrganizationId: null, organizations: null, password: null, phone: null, reminders: null, socialSecurityNr: null, username: null, token: null };

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]

    });

    this.loginFormBankId = this.formBuilder.group({
      //socsecurityno: ['', Validators.required]
    });

    this.RequestResetForm = this.formBuilder.group({
      username: ['', Validators.required]
    });

    this.SetNewPasswordForm = this.formBuilder.group({
      password: ['', Validators.required]
    });

    this.NewCompanyForm = this.formBuilder.group({
      orgName: ['', Validators.required],
      orgNr: ['', Validators.required],
      orgStreetAddress: ['', Validators.required],
      orgPostalCode: ['', Validators.required],
      orgCity: ['', Validators.required],
      orgEmailInvoice: ['', Validators.required],

      orgUserFirstName: ['', Validators.required],
      orgUserLastName: ['', Validators.required],
      orgUserNr: ['', Validators.required],
      orgUserPhone: ['', Validators.required],
      orgUserMail: ['', Validators.required],
    });


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.pwtoken = this.route.snapshot.queryParams['pwtoken'] || '/';
    this.isRequest = this.route.snapshot.queryParams['isRequest'] || '/';
    this.isChange = this.route.snapshot.queryParams['isChange'] || '/';
    this.isNewCompany = this.route.snapshot.queryParams['isNewCompany'] || '/';
    this.isOldLogin = this.route.snapshot.queryParams['isOldLogin'] || '/';
    
    this.username = this.route.snapshot.queryParams['email'] || '/';
    console.log('------- returnUrl PARAM : ' + this.returnUrl);
    console.log('------- isRequest PARAM : ' + this.isRequest);
    console.log('------- isChange PARAM : ' + this.isChange);
    console.log('------- isNewCompany PARAM : ' + this.isNewCompany);
    console.log('------- isOldLogin PARAM : ' + this.isOldLogin);
    this.isRequestResetAction = (this.isRequest == 'true');
    this.isSetNewPasswordAction = (this.isChange == 'true');
    this.isNewCompanyAction = (this.isNewCompany == 'true');
    this.isNewCompanyResultAction = (this.isNewCompanyResult == 'true');
    this.isOldLoginAction = (this.isOldLogin == 'true');
   // this.isLoginAction = (this.isChange != 'true') && (this.isRequest != 'true') && (this.isNewCompany != 'true');
    this.isLoginActionBankId = (this.isChange != 'true') && (this.isRequest != 'true') && (this.isNewCompany != 'true') && (this.isOldLogin != 'true');
    this.isLoginActionBankButton = (this.isChange != 'true') && (this.isRequest != 'true') && (this.isNewCompany != 'true') && (this.isOldLogin != 'true');
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get f2() { return this.RequestResetForm.controls; }
  get f3() { return this.SetNewPasswordForm.controls; }
  get f4() { return this.NewCompanyForm.controls; }
  get f5() { return this.loginFormBankId.controls; }

  //isLoginAction: boolean = true;
  isLoginActionBankId: boolean = true;

  isRequestResetAction: boolean = false;

  isNewCompanyAction: boolean = false;
  isNewCompanyResultAction: boolean = false;
  isOldLoginAction: boolean = false;
  isSetNewPasswordAction: boolean = false;
  isSetNewPasswordActionFinished: boolean = false;
  isSetNewPasswordSetFinished: boolean = false;
  isLoginActionBankButton: boolean = true;


    onSubmit() {
      this.submitted = true;

      console.log('submit');
    
      // stop here if form is invalid
      if (this.loginForm.invalid) {
        return;
      }

      this.loading = true;
      this.service.login(this.f.username.value, this.f.password.value)
        .pipe(first())
              .subscribe(
                data => {

                  let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
                  (async () => {
                    await sleep(1000);
                    this.zone.run(() => {
                      this.router.navigate([this.returnUrl]);
                    });
                  })();
                
                  },
                  error => {
                      this.error = error;
                      this.loading = false;
                  });
  }

  onSubmitBankId() {
    this.submitted = true;
    this.isLoginActionBankButton = false;
    console.log('submitBankId');

    // stop here if form is invalid
    if (this.loginFormBankId.invalid) {
      return;
    }

    this.loading = true;

    this.service.InitBankId()
      .pipe(first())
      .subscribe(
        data => {
          this.bankIdResponse = data.shortResponse;
          this.QRimageSource = this.bankIdResponse.qrImage + '?rand=' + Math.random();
          this.runnerQR(this.bankIdResponse);
          
        },
        error => {
          this.error = error;
          this.loading = false;
        });
    
  }

  runnerQR(t) {
    this.refreshQR(t);

    this.timeoutIdStatus = setTimeout(() => {
        this.runnerQR(t);
    }, 3000);
    this.runnerStatus();
  }

  refreshQR(t) {
    this.QRimageSource = t.qrImage + '?rand=' + Math.random();
  }

  resetBI()
  {
    window.location.reload();
  }
  gotoCompanyCreate()
  {
    this.isNewCompanyAction = true;
    this.isSetNewPasswordSetFinished = false;
    this.isLoginActionBankButton = false;
    this.isRequestResetAction = false;
    this.isSetNewPasswordAction = false;
    
    this.isNewCompanyResultAction = false;
    
    this.isLoginActionBankId = false;
  }
  goToLogin() {
    this.isSetNewPasswordSetFinished = false;
    this.isLoginActionBankButton = true;
    this.isRequestResetAction = false;
    this.isSetNewPasswordAction = false;
    this.isNewCompanyAction = false;
    this.isNewCompanyResultAction = false;
    //this.isLoginAction = true;
    this.isLoginActionBankId = true;
  }

  private currentUserSubject: BehaviorSubject<User>;

  refreshStatus() {
    console.log('### NR ' + this.bankIdResponse.orderRef)
    this.service.CheckBankId(this.bankIdResponse.orderRef)
      .pipe(first())
      .subscribe(
        data => {
          this.statusResponse = data.shortResponse;
          console.log(this.statusResponse);
          if (this.statusResponse.HintCode == "outstandingTransaction")
          {
            console.log("outstandingTransaction");
          }
          if (this.statusResponse.HintCode == "userSign" || this.statusResponse.statusMessage == "complete") {
            console.log("QR Scannad");
            if (this.statusResponse.statusMessage == "complete")
            {
              if (this.statusResponse.userInformation != null)
              {
                var user = this.service.loginBI(this.statusResponse.userInformation);
                //console.log("Användare inloggad");
                //localStorage.setItem('currentUser', JSON.stringify(this.statusResponse.userInformation));
                //this.currentUserSubject.next(this.statusResponse.userInformation);

                //this.organizationservice.list().subscribe(
                //  (orgRes: any) => {
                //    //if (this.organizationService.ActiveOrganization) {
                //    //}
                //    //else {
                //    console.log('sätt aktiv org till ' + this.statusResponse.userInformation.mainOrganizationId);

                //    this.organizationservice.ActiveOrganization = orgRes.find(o => o.id == this.statusResponse.userInformation.mainOrganizationId);
                //    //}
                //  },
                //  err => {
                //    if (err.status == 400)
                //      console.log('400: ' + err);
                //    else
                //      console.log(err);
                //  }
                //);
                if (user != null) {
                  let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
                  (async () => {
                    await sleep(500);
                    this.zone.run(() => {
                      this.router.navigate([this.returnUrl]);
                    });
                  })();
                }
              }
              
              console.log(this.statusResponse);
              clearTimeout(this.timeoutIdStatus); 
              
            }
            
          }
          console.log(this.statusResponse.success);
          console.log(this.statusResponse.statusMessage);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
    
  }
  runnerStatus() {
    this.refreshStatus();
    //this.refreshStatus();
    // this.timeoutIdStatus = setTimeout(() => {
    //   this.runnerStatus();

    //}, 6000);
  }

  onSubmitRequest() {
    this.submitted = true;

    console.log('onSubmitRequest');

    // stop here if form is invalid
    if (this.RequestResetForm.invalid) {
      return;
    }

    this.loading = true;


    this.service.RequestReset(this.f2.username.value).subscribe((res: any) => {
      this._snackBar.open('Mail skickat till angiven adress, öppna och följ instruktioner', '', {
        duration: 5 * 1000,
      });
      this.isRequestResetAction = false;
      this.isSetNewPasswordAction = false;
      this.isSetNewPasswordActionFinished = true;
      this.isSetNewPasswordSetFinished = false;
      //this.isLoginAction = false;
      this.isLoginActionBankId = false;
      },
      error => {
        this._snackBar.open('Något gick fel, försök igen', '', {
          duration: 5 * 1000,
        });
        this.error = error;
        this.loading = false;
      });

  }

    onSubmitNewPassword() {
      this.submitted = true;

      console.log('onSubmitNewPassword');

      // stop here if form is invalid
      if (this.SetNewPasswordForm.invalid) {
        return;
      }

      this.loading = true;


      this.service.SetPassword(this.username, this.pwtoken, this.f3.password.value).subscribe((res: any) => {
        this._snackBar.open('Lösenordet har uppdaterats', '', {
          duration: 5 * 1000,
        });
        this.isSetNewPasswordSetFinished = true;
        this.isRequestResetAction = false;
        this.isSetNewPasswordAction = false;
        //this.isLoginAction = false;
        this.isLoginActionBankId = false;
      },
        error => {
          this._snackBar.open('Något gick fel, försök igen', '', {
            duration: 5 * 1000,
          });
          this.error = error;
          this.loading = false;
        });

 

  }
  onSubmitNewCompany() {
    this.submitted = true;

    console.log('onSubmitNewCompany');

    // stop here if form is invalid
    if (this.NewCompanyForm.invalid) {
      return;
    }

    this.loading = true;
    
    this.organizationservice.addOrganizationByUser(this.organization, this.user).subscribe((res: any) => {
      this._snackBar.open('Grunduppgifterna är sparade', '', {
        duration: 5 * 1000,
      });
      this.isSetNewPasswordSetFinished = false;
      this.isLoginActionBankButton = true;
      this.isRequestResetAction = false;
      this.isSetNewPasswordAction = false;
      this.isNewCompanyAction = false;
      this.isNewCompanyResultAction = true;
      //this.isLoginAction = true;
      this.isLoginActionBankId = false;
      
    },
      error => {
        this._snackBar.open('Något gick fel, försök igen', '', {
          duration: 5 * 1000,
        });
        this.error = error;
        this.loading = false;
      });



  }
}

