import { AuthGuard } from './auth/auth.guard';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    DateAdapter,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,  
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE
}
from '@angular/material';

import { MatIconRegistry } from '@angular/material';

import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppComponent } from './app.component';
// import { NavMenuComponent } from './nav-menu/nav-menu.component';
// import { AddsafetySidebarComponent } from './addsafety-sidebar/addsafety-sidebar.component';
// import { ToolbarComponent } from './toolbar/toolbar.component';

// import { HomeComponent } from './home/home.component';
// import { CounterComponent } from './counter/counter.component';
// import { FetchDataComponent } from './fetch-data/fetch-data.component';
// import { OrganizationInfoComponent } from './organization/info/info.component';
// import { QuestionListComponent } from './question/list/list.component';
// import { QuestionAddComponent } from './question/add/add.component';
// import { PlantInfoComponent } from './plant/info/info.component';
// import { PlantListComponent } from './plant/list/list.component';
// import { PlantAddComponent } from './plant/add/add.component';

// import { ChecklistListComponent } from './checklist/list/list.component';
// import { ChecklistEditComponent } from './checklist/edit/edit.component';
// import { ChecklistAddComponent } from './checklist/add/add.component';
// import { ChecklistControlComponent } from './checklist/control/control.component';
// import { WorktypeListComponent } from './worktype/list/list.component';
// import { AnswerListComponent } from './answer/list/list.component';
// import { AnswerDetailComponent } from './answer/detail/detail.component';
// import { IncidentListComponent } from './incident/list/list.component';
// import { IncidentDetailComponent } from './incident/detail/detail.component';

// import { DashboardComponent } from './dashboard/dashboard.component';

// import { AuthInterceptor } from './auth/auth.interceptor';
// import { JwtInterceptor } from './auth/jwt.interceptor';

import { LoginComponent } from './user/login/login.component';
import { ResetPasswordComponent } from './user/resetpassword/resetpassword.component';
import { NewCompanyComponent } from './user/newcompany/newcompany.component';


// import { UserService } from './services/user.service';
// import { OrganizationService } from './services/organization.service';
// import { QuestionService } from './services/question.service';
// import { ChecklistService } from './services/checklist.service';
// import { WorktypeService } from './services/worktype.service';
// import { PlantService } from './services/plant.service';
// import { AnswerService } from './services/answer.service';
// import { IncidentService, Incident } from './services/incident.service';
// import { ImprovementService } from './services/improvment.service';


// import { DashboardModule } from './dashboard/dashboard.module';

// import {DragDropModule} from '@angular/cdk/drag-drop';

import {ThemeModule} from './@theme/theme.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMatDateAdapter, NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from './customngxdatetimeadapter';
//import { LogeventAddComponent } from './pages/logevent/add/add.component';
//import { LogeventDetailComponent } from './pages/logevent/detail/detail.component';
//import { LogeventListComponent } from './pages/logevent/list/list.component';
// import { CommonDataService } from './services/commondata.service';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// const appRoutes: Routes = [
//   { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
//   { path: 'counter', component: CounterComponent, canActivate: [AuthGuard] },
//   { path: 'organization/info', component: OrganizationInfoComponent, canActivate: [AuthGuard] },
//   { path: 'fetch-data', component: FetchDataComponent },
//   { path: 'user/login', component: LoginComponent },
//   { path: 'question/list', component: QuestionListComponent, canActivate: [AuthGuard] },
//   { path: 'question/add', component: QuestionAddComponent, canActivate: [AuthGuard] },
//   { path: 'plant/list', component: PlantListComponent, canActivate: [AuthGuard] },
//   { path: 'plant/add', component: PlantAddComponent, canActivate: [AuthGuard] },
//   { path: 'plant/:id', component: PlantInfoComponent, canActivate: [AuthGuard] },
//   { path: 'checklist/list', component: ChecklistListComponent, canActivate: [AuthGuard] },
//   { path: 'checklist/add', component: ChecklistAddComponent, canActivate: [AuthGuard] },
//   { path: 'checklist/control', component: ChecklistControlComponent, canActivate: [AuthGuard] },
//   { path: 'checklist/:id', component: ChecklistEditComponent, canActivate: [AuthGuard] },
//   { path: 'worktype/list', component: WorktypeListComponent, canActivate: [AuthGuard] },
//   { path: 'answer/list', component: AnswerListComponent, canActivate: [AuthGuard] },
//   { path: 'answer/:id', component: AnswerDetailComponent, canActivate: [AuthGuard] },
//   { path: 'incident/list', component: IncidentListComponent, canActivate: [AuthGuard] },
//   { path: 'incident/:id', component: IncidentDetailComponent, canActivate: [AuthGuard] },
//   { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
//   //{ path: '', component: HomeComponent, pathMatch: 'full' },
  
// ];
const appRoutes: Routes = [
  { path: 'user/login/?', component: LoginComponent },
  { path: 'user/login?', component: LoginComponent },
  { path: 'user/login', component: LoginComponent },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];


@NgModule({

  declarations: [
    AppComponent,
    LoginComponent,
    //LogeventAddComponent,
    //LogeventDetailComponent,
    //LogeventListComponent,
    // NavMenuComponent,
    // HomeComponent,
    // CounterComponent,
    // OrganizationInfoComponent,
    // QuestionListComponent,
    // QuestionAddComponent,
    // ChecklistListComponent,
    // ChecklistEditComponent,
    // ChecklistAddComponent,
    // ChecklistControlComponent,
    // WorktypeListComponent,
    // PlantInfoComponent, PlantListComponent, PlantAddComponent,
    // AddsafetySidebarComponent,
    // FetchDataComponent,
    // ToolbarComponent,
    // AnswerListComponent, AnswerDetailComponent,
    // IncidentListComponent, IncidentDetailComponent
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    
    
    ThemeModule.forRoot(),

    BrowserAnimationsModule,
    PerfectScrollbarModule,
    LoadingBarRouterModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,

    // DragDropModule,

    // DashboardModule,

    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
    
    
  ],
  // providers: [UserService, OrganizationService, QuestionService, ChecklistService, WorktypeService, PlantService, AnswerService, ImprovementService, IncidentService, CommonDataService, {
  //   provide: HTTP_INTERCEPTORS, 
  //   useClass: AuthInterceptor,
  //   multi: true
  // },
  // {
  //   provide: PERFECT_SCROLLBAR_CONFIG,
  //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  // },
  // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'thumbs-up',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/materials/thumbup-icon.svg'));
  }
}
