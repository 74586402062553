import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
//  NbActionsModule,
//  NbLayoutModule,
//  NbMenuModule,
//  NbSearchModule,
//  NbSidebarModule,
//  NbUserModule,
//  NbContextMenuModule,
//  NbButtonModule,
//  NbSelectModule,
//  NbIconModule,
  AddSafetyThemeModule,
} from '@framework/theme';
//import { NbEvaIconsModule } from '@nebular/eva-icons';
//import { NbSecurityModule } from '@nebular/security';

// import {
//   FooterComponent,
//   HeaderComponent,
//   SearchInputComponent,
//   TinyMCEComponent,
// } from './components';
// import {
//   CapitalizePipe,
//   PluralPipe,
//   RoundPipe,
//   TimingPipe,
//   NumberWithCommasPipe,
// } from './pipes';
// import {
//   OneColumnLayoutComponent,
//   ThreeColumnsLayoutComponent,
//   TwoColumnsLayoutComponent,
// } from './layouts';

import { DEFAULT_THEME } from './styles/theme.default';
//import { COSMIC_THEME } from './styles/theme.cosmic';
//import { CORPORATE_THEME } from './styles/theme.corporate';
//import { DARK_THEME } from './styles/theme.dark';

const ADDSAFETY_MODULES = [
//   NbLayoutModule,
//   NbMenuModule,
//   NbUserModule,
//   NbActionsModule,
//   NbSearchModule,
//   NbSidebarModule,
//   NbContextMenuModule,
//   NbSecurityModule,
//   NbButtonModule,
//   NbSelectModule,
//   NbIconModule,
//   NbEvaIconsModule,
];
const COMPONENTS = [
//   HeaderComponent,
//   FooterComponent,
//   SearchInputComponent,
//   TinyMCEComponent,
//   OneColumnLayoutComponent,
//   ThreeColumnsLayoutComponent,
//   TwoColumnsLayoutComponent,
];
const PIPES = [
//   CapitalizePipe,
//   PluralPipe,
//   RoundPipe,
//   TimingPipe,
//   NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, ...ADDSAFETY_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        ...AddSafetyThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME ],
        ).providers,
      ],
    };
  }
}